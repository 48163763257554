import axios from "axios";
import vm from '../main';
import {setCookie} from "../utils/utils";
const {config: {baseURL}} = vm.$store.state;

const instance = axios.create({
  baseURL,
  timeout: 24 * 3600 * 1000
});

instance.interceptors.request.use((req) => {
    const {token} = vm.$store.state;
    if (!token && req.url !== "/login") {
        return Promise.reject("token已过期");
    }
    req.headers.common['Authorization'] = `Bearer ${token}`;
    return req;
});

instance.interceptors.response.use(res => res.data);

function http(method, path, params={}, config={}) {
  return new Promise((resolve, reject) => {
    let req = null;
    if(method === 'get') req = instance.get(path, {params});
    if(method === 'post') req = instance.post(path, params, config);
    if(method === 'put') req = instance.put(path, params, config);
    if(method === 'delete') req = instance.delete(path, {params});
    req.then(res => _handleSuccess(res, resolve, reject))
    .catch(error => _handleError(error))
  })
}

function _handleSuccess(res, resolve, reject) {
  switch(res.code) {
    case 0:
      resolve(res);
      break;
    case 101:
      clearToken();
      _register();
      break;
    default:
      vm.$message(res.msg);
      return reject(res.msg);
  }
}

function _handleError(error) {
  vm.$message('服务器错误:' + error);
}

function clearToken() {
  setCookie('token', '');
  localStorage.setItem('token', '');
  vm.$store.commit('setToken', '');
}

function clearRefreshToken() {
  setCookie('refresh_token', '');
  localStorage.setItem('refresh_token', '');
  vm.$store.commit('setRefreshToken', '');
}

function clearUserInfo() {
  setCookie('user_info', '');
  localStorage.setItem('user_info', '');
  vm.$store.commit('setRefreshToken', null);
}

function flush() {
  clearToken();
  clearRefreshToken();
  clearUserInfo();
}

function _register() { // token过期获取新的token
  const refresh_token = vm.$store.state.refresh_token;
  if(!refresh_token) {
    vm.$message("token已过期，请重新登录");
    return vm.$router.push({name: 'Login'});
  }
  axios({
    method: 'post',
    url: `${baseURL}/refresh/token`,
    data: {},
    headers: {
      'Authorization': `Bearer ${refresh_token}`
    }
  }).then(res => {
    if (res.data.code === 101) {
        flush();
        vm.$message("token已过期，请重新登录");
        return vm.$router.push({name: 'Login'});
    }
    const {access_token, info} = res.data.data;
    setCookie('token', access_token);
    localStorage.setItem('token', access_token);
    vm.$store.commit('setToken', access_token);
    setCookie('user_info', JSON.stringify(info), 7200);
    localStorage.setItem('user_info', info);
    vm.$store.commit('setUserInfo', info);
    vm.$router.go(0);
  });
}

export const get = (...arg) => http('get', ...arg);
export const post = (...arg) => http('post', ...arg);
export const put = (...arg) => http('put', ...arg);
export const del = (...arg) => http('delete', ...arg);