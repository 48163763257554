import {get, post, put, del} from "./http";

export const getLogin = params => post('/login', params); // 登录

// admin
export const getAdminList = params => get('/admin', params); // 获取分页数据
export const addAdminItem = params => put('/admin', params); // 添加用户
export const deleteAdminList = params => del('/admin', params); // 根据条件伪删除数据
export const getAdminAll = params => get('/admin/all', params); // 获取全部数据
export const getAdminInfo = () => post('/admin/info'); // 获取后台登录用户信息
export const queryAdminItem = id => get(`/admin/${id}`); // 查询单条数据
export const editAdminItem = (id, params) => post(`/admin/${id}`, params); // 修改单条数据
export const deleteAdminItem = id => del(`/admin/${id}`); // 删除单条数据

// Author
export const getAuthorList = params => get('/author', params); // 获取分页列表
export const addAuthorItem = params => put('/author', params); // 添加作者
export const deleteAuthorList = params => del('/author', params); // 根据条件伪删除数据
export const getAuthorAll = params => get('/author/all', params); // 获取全部数据
export const queryAuthorItem = id => get(`/author/${id}`); // 查询单条数据
export const editAuthorItem = (id, params) => post(`/author/${id}`, params); // 修改单条数据
export const deleteAuthorItem = id => del(`/author/${id}`); // 删除单条数据

// Article
export const getArticleList = params => get('/article', params); // 获取分页列表
export const addArticleItem = params => put('/article', params); // 添加书籍
export const deleteArticleList = params => del('/article', params); // 根据条件伪删除数据
export const getArticleAll = params => get('/article/all', params); // 获取全部数据
export const getArticleColumns = params => get('/article/columns', params); // 获取文章栏目数据
export const getTagAll = params => get('/article/tags', params); // 获取全部标签数据
export const queryArticleItem = id => get(`/article/${id}`); // 查询单条数据
export const editArticleItem = (id, params) => post(`/article/${id}`, params); // 修改单条数据
export const deleteArticleItem = id => del(`/article/${id}`); // 删除单条数据

// Column
export const getColumnList = params => get('/column', params); // 查询分页列表
export const addColumnItem = params => put('/column', params); // 添加分类
export const deleteColumnList = params => del('/column', params); // 根据条件伪删除数据
export const getColumnAll = params => get('/column/all', params); // 获取全部数据
export const queryColumnItem = id => get(`/column/${id}`); // 查询单条数据
export const editColumnItem = (id, params) => post(`/column/${id}`, params); // 修改单条数据
export const deleteColumnItem = id => del(`/column/${id}`); // 删除单条数据

// Special
export const getSpecialList = params => get('/special', params); // 查询分页列表
export const addSpecialItem = params => put('/special', params); // 添加分类
export const deleteSpecialList = params => del('/special', params); // 根据条件伪删除数据
export const getSpecialAll = params => get('/special/all', params); // 获取全部数据
export const querySpecialItem = id => get(`/special/${id}`); // 查询单条数据
export const editSpecialItem = (id, params) => post(`/special/${id}`, params); // 修改单条数据
export const deleteSpecialItem = id => del(`/special/${id}`); // 删除单条数据
export const specialAssoc = params => post('/special/assoc', params); // 关联数据
export const specialAssocList = params => get('/special/assoc', params); // 关联数据

// Music
export const getMusicList = params => get('/music', params); // 查询分页列表
export const addMusicItem = params => put('/music', params); // 添加分类
export const deleteMusicList = params => del('/music', params); // 根据条件伪删除数据
export const getMusicAll = params => get('/music/all', params); // 获取全部数据
export const queryMusicItem = id => get(`/music/${id}`); // 查询单条数据
export const editMusicItem = (id, params) => post(`/music/${id}`, params); // 修改单条数据
export const deleteMusicItem = id => del(`/music/${id}`); // 删除单条数据

// Comment
export const getCommentList = params => get('/comment', params); // 查询分页列表
export const addCommentItem = params => put('/comment', params); // 添加分类
export const deleteComments = params => del('/comment', params); // 根据条件伪删除数据
export const getCommentAll = params => get('/comment/all', params); // 获取全部数据
export const queryCommentItem = id => get(`/comment/${id}`); // 查询单条数据
export const editCommentItem = (id, params) => post(`/comment/${id}`, params); // 修改单条数据
export const deleteCommentItem = id => del(`/comment/${id}`); // 删除单条数据

// Tag
export const getKeywordsList = params => get('/keywords', params); // 查询分页列表
export const addKeywordsItem = params => put('/keywords', params); // 添加分类
export const deleteKeywordsList = params => del('/keywords', params); // 根据条件伪删除数据
export const getKeywordsAll = params => get('/keywords/all', params); // 获取全部数据
export const queryKeywordsItem = id => get(`/keywords/${id}`); // 查询单条数据
export const editKeywordsItem = (id, params) => post(`/keywords/${id}`, params); // 修改单条数据
export const deleteKeywordsItem = id => del(`/keywords/${id}`); // 删除单条数据

// Book
export const getBookList = params => get('/book', params); // 获取分页列表
export const addBookItem = params => put('/book', params); // 添加书籍
export const deleteBookList = params => del('/book', params); // 根据条件伪删除数据
export const getBookAll = params => get('/book/all', params); // 获取全部数据
export const queryBookItem = id => get(`/book/${id}`); // 查询单条数据
export const editBookItem = (id, params) => post(`/book/${id}`, params); // 修改单条数据
export const deleteBookItem = id => del(`/book/${id}`); // 删除单条数据

// Category
export const getCateList = params => get('/cate', params); // 查询分页列表
export const addCateItem = params => put('/cate', params); // 添加分类
export const deleteCateList = params => del('/cate', params); // 根据条件伪删除数据
export const getCateAll = params => get('/cate/all', params); // 获取全部数据
export const getCateCascade = params => get('/cate/cascade', params); // 获取全部级联数据
export const queryCateItem = id => get(`/cate/${id}`); // 查询单条数据
export const editCateItem = (id, params) => post(`/cate/${id}`, params); // 修改单条数据
export const deleteCateItem = id => del(`/cate/${id}`); // 删除单条数据

// Chapter
export const getChapterList = params => get('/chapter', params); // 查询分页列表
export const addChapterItem = params => put('/chapter', params); // 添加分类
export const deleteChapterList = params => del('/chapter', params); // 根据条件伪删除数据
export const getChapterAll = params => get('/chapter/all', params); // 获取全部数据
export const queryChapterItem = id => get(`/chapter/${id}`); // 查询单条数据
export const editChapterItem = (id, params) => post(`/chapter/${id}`, params); // 修改单条数据
export const deleteChapterItem = id => del(`/chapter/${id}`); // 删除单条数据

// Collect
export const getCollectList = params => get('/collect', params); // 查询分页列表
export const addCollectItem = params => put('/collect', params); // 添加分类
export const deleteCollectList = params => del('/collect', params); // 根据条件伪删除数据
export const getCollectAll = params => get('/collect/all', params); // 获取全部数据
export const queryCollectItem = id => get(`/collect/${id}`); // 查询单条数据
export const editCollectItem = (id, params) => post(`/collect/${id}`, params); // 修改单条数据
export const deleteCollectItem = id => del(`/collect/${id}`); // 删除单条数据

// Advertise
export const getAdvertiseList = params => get('/advertise', params); // 查询分页列表
export const addAdvertiseItem = params => put('/advertise', params); // 添加分类
export const deleteAdvertiseList = params => del('/advertise', params); // 根据条件伪删除数据
export const getAdvertiseAll = params => get('/advertise/all', params); // 获取全部数据
export const getAdvertiseProfile = params => get('/advertise/profile', params); // 获取配置数据
export const queryAdvertiseItem = id => get(`/advertise/${id}`); // 查询单条数据
export const editAdvertiseItem = (id, params) => post(`/advertise/${id}`, params); // 修改单条数据
export const deleteAdvertiseItem = id => del(`/advertise/${id}`); // 删除单条数据

// Department
export const getDepartmentAll = params => get('/department/all', params); // 获取全部数据
export const getDepartmentCascade = params => get('/department/cascade', params); // 获取部门级联数据

// Profile
export const getProfileList = params => get('/profile', params);
export const getProfileAll = params => get('/profile/all', params);
export const getProfileTypeAll = params => get('/profile/types', params);
// ProfileValue
export const getProfileValues = params => get('/profile/values', params);
export const getProfileValueList = (id, params) => get(`/profile/${id}`, params);
export const addProfileValueItem = params => put('/profile/value', params); // 添加分类
export const deleteProfileValue = params => del('/profile/value', params); // 根据条件删除数据
export const deleteProfileValueItem = id => del(`/profile/value/${id}`); // 删除单条数据
export const editProfileValueItem = (id, params) => post(`/profile/value/${id}`, params); // 修改单条数据

// Decision
export const getDecisionList = params => get('/decision', params);
export const getDecisionAll = params => get('/decision/all', params);
export const getDecisionTypeAll = params => get('/decision/types', params);
export const getInputWayAll = params => get('/decision/input_ways', params);
export const setDecisionItem = params => post(`/decision/set`, params); // 设置决策

// Upload
export const upLoadImg = params => post('/upload', params); // 上传单个文件
export const upLoadMultipleImg = params => post('/upload/multi', params); // 上传多个文件